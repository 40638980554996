.rbc-container {
  padding: 3rem;
  margin-top: 2rem;
  background: linear-gradient(135deg, #cadff7, #82b1ea);
  border-radius: 15px;
  box-shadow: 0 10px 20px var(--color-shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.button-container {
  padding: 1rem;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.back-btn {
  align-self: flex-start;
  background-color: var(--color-main);
  color: var(--color-white);
  padding: 10px 15px;
  border-radius: 3px;
  margin-bottom: 2rem;
  text-decoration: none;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  box-shadow:
    0 8px 16px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  align-items: center;
  gap: 10px;
}

.back-btn:hover {
  background-color: #005bb5;
  transform: translateY(-2px);
}

.vac-btn {
  align-self: flex-start;
  background-color: var(--color-main);
  color: var(--color-white);
  padding: 10px 15px;
  border-radius: 3px;
  margin-bottom: 2rem;
  text-decoration: none;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  box-shadow:
    0 8px 16px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  align-items: center;
  gap: 10px;
}

.vac-btn:hover {
  background-color: #005bb5;
  transform: translateY(-2px);
}

.calendar-title {
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
}

.calendar-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.calendar-wrapper {
  flex: 2;
  margin-right: 2rem;
}

.sel-info-box {
  background-color: #f9fafa;
  border: 1px solid #b5b5ba;
  border-radius: 2px;
  color: #303132;
  padding: 20px;
  width: 90%;
  text-align: justify;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-weight: 400;
}

.rbc-calendar {
  background-color: var(--color-secondary-light);
  border-radius: 10px;
  box-shadow: 0 4px 15px var(--color-shadow);
  overflow: hidden;
  width: 100%;
  min-width: 300px;
  max-width: 800px;
  margin: 0 auto;
}

.rbc-toolbar {
  background-color: rgba(220, 187, 139, 0.265);
  color: #333;
  padding: 1rem;
}

.rbc-today {
  background-color: rgba(0, 115, 230, 0.2);
}

.input-group {
  margin-bottom: 15px; /* Space between input groups */
}

.input-group label {
  display: block;
  margin-bottom: 5px; /* Space between label and input */
}

.close-button {
  position: absolute;
  top: 20px; /* Adjust top position as needed */
  right: 10px; /* Adjust right position as needed */
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.event-form h3 {
  margin: 0 0 10px;
}

.rbc-event {
  border-radius: 1px;
  font-size: 80%;
}

.event-form label {
  display: block;
  margin-top: 10px;
}

.event-form input[type='date'],
.event-form select {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-buttons button {
  flex-grow: 1;
  margin-right: 10px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: #007bff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-buttons button:last-child {
  margin-right: 0;
}

.form-buttons button:nth-child(2) {
  background-color: #e74c3c;
}

.form-buttons button:hover {
  background-color: #0056b3;
}

.selected-info {
  margin-top: 2rem;
  text-align: center;
}

.selected-info button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.selected-info button:hover {
  background-color: #c82333;
}

.selected-info h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.selected-info div {
  margin-top: 10px;
}

.selected-info {
  margin-top: 2rem;
  text-align: center;
}

.selected-info h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.selected-info div {
  margin-top: 10px;
}

.bg-gray {
  background-color: rgba(242, 242, 242, 0.3);
}
