.productivity-table-container {
  margin-top: 2rem;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 12px;
  text-align: left;
}
.prodreport-table {
  width: 100%;
  border-collapse: collapse;
  display: table;
}
.prodreport-table thead {
  background-color: #ccc;
}
.prodreport-table th,
.prodreport-table td {
  font-size: 1rem;
  padding: 0.5rem;
}
.prodreport-table th {
  font-weight: 600;
}
.prodreport-table tr:nth-child(even) {
  background-color: #e1e1e1;
}

@media (max-width: 700px) {
  .daily-rate .MuiInputBase-root {
    width: 70px;
    height: 30px;
  }
  .monthly-rate .MuiInputBase-root {
    width: 100px;
    height: 30px;
  }
}

/* Add more styles as needed */
@media (max-width: 480px) {
  .prodreport-table th,
  .prodreport-table td {
    font-size: 14px;
  }
  .daily-rate .MuiInputBase-root {
    width: 70px;
    height: 30px;
  }
  .monthly-rate .MuiInputBase-root {
    width: 100px;
    height: 30px;
  }
}
