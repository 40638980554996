.prod-container {
  margin-top: 4.5rem;
}
.table-container {
  margin-top: 2rem;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 12px;
  text-align: left;
}

@media (max-width: 600px) {
  .table-container {
    margin-top: 1rem;
  }
}
