.modal-portal {
  position: relative;
}

.modalContainer {
  margin-left: 2rem;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: grey;
}

.close-button:hover {
  background-color: transparent;
}

.upload-button {
  background-color: skyblue;
  color: black;
}

.upload-button:hover {
  background-color: lightblue;
}

.opendialog-button {
  background-color: transparent;
  color: black;
  border: 2px solid grey;
}

.dropbox {
  border: '2px dashed #cccccc';
  border-radius: '4px';
  padding: '20px';
  text-align: 'center';
  cursor: 'pointer';
}

.filenameTextbox {
  display: flex;
}

.pdfTextbox {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}

.errorMessage {
  color: red;
}

.categoryCB {
  width: 81%;
}

@media (max-width: 815px) {
}
