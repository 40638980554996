.report-container {
  margin: 2rem;
  background-color: #f5f5f5;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 40px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #333;
}
.report-container h3 {
  font-size: 28px;
}
.report-header {
  width: 100%;
  display: flex;
  padding-left: 1rem;
  gap: 2rem;
  align-items: center;
  border-bottom: 3px solid var(--color-secondary-light);
}
.tab-container {
  background-color: #f5f5f5;
}

@media (max-width: 829px) {
  .report-header {
    gap: 0.5rem;
    padding-left: 0;
  }
  .dropdown.year .MuiAutocomplete-inputRoot {
    width: 100px;
    font-size: 14px;
  }
}
@media (max-width: 662px) {
  .dropdown.year .MuiAutocomplete-inputRoot {
    width: 80px;
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .report-container {
    padding: 1.5rem;
  }
  .report-container h3 {
    font-size: 24px;
    text-align: center;
  }
  .report-header {
    width: 100%;
    display: block;
  }
  .dropdown.year {
    text-align: center;
  }
  .dropdown.year .MuiAutocomplete-inputRoot {
    width: 80px;
    height: 30px;
    font-size: 10px;
  }
}
