.calendar-event-list {
  text-align: left;
  padding: 0;
  list-style: none;
}

.calendar-event-list li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.calendar-event-item-badge {
  vertical-align: top;
  margin-top: 8px;
  width: 6px;
  height: 6px;
}
