.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-shadow);
  z-index: 900;
  cursor: pointer;
}
