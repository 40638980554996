/* .content-wrap is assumed to wrap all content except the footer */
.content-wrap {
  min-height: calc(100vh - 120px);
}

.footer {
  background-color: var(--color-main);
  padding: 1rem 1rem 1rem 1rem;
}

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
}

.footer-links p {
  font-size: 14px;
  line-height: 15px;
  color: white;
  font-weight: 600;
}

hr {
  color: white !important;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.footer-copyright {
  font-size: 11px;
  line-height: 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  padding: 10px 0;
}

/* Responsive Adjustments */
@media (max-width: 976px) {
  .footer-links p {
    font-size: 11px;
  }
  .footer-copyright {
    font-size: 10px;
  }
}

@media (max-width: 729px) {
  .footer-links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5rem;
  }

  .footer-links p {
    margin: 0.5rem;
  }
}

@media (max-width: 576px) {
  .footer-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
  }

  .footer-links p {
    margin: 0.5rem;
  }
}
