.summary-table {
  display: table;
  width: 100%;
  padding: 1rem 0 1rem 0;
}
.summary-table th,
.summary-table td {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem;
}
.summary-table th {
  font-weight: 600;
}
.summary-table tr:nth-child(even) {
  background-color: #e1e1e1;
}
@media (max-width: 700px) {
  .summary-table th,
  .summary-table td {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .summary-table th,
  .summary-table td {
    font-size: 12px;
  }
}
