.scheduling-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin: 3rem;
  margin-top: 6rem;
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #2c3e50, #34495e);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 15px;
  box-shadow: 0 10px 20px var(--color-shadow);
}

.link-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background: rgba(154, 225, 243, 0.4);
  border-radius: 10px;
  box-shadow: 0 4px 10px var(--color-shadow);
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.link-box:hover {
  transform: translateY(-10px);
  background: rgba(154, 225, 243, 0.8);
  box-shadow: 0 6px 15px var(--color-shadow);
}

.calendar-link,
.vacation-link {
  text-decoration: none;
  color: #333;
  display: block;
}

.link-box:hover .calendar-icon,
.link-box:hover .vacation-icon,
.link-box:hover h3 {
  color: #0073e6;
}

.link-box h3 {
  margin-top: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.calendar-icon,
.vacation-icon {
  color: #2c3e50;
  transition: color 0.3s ease;
}

@media (max-width: 768px) {
  .scheduling-container {
    grid-template-columns: 1fr;
    margin: 1rem;
    padding: 1rem;
  }

  .link-box h3 {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  .scheduling-container {
    grid-template-columns: 1fr;
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .link-box h3 {
    font-size: 1rem;
  }
}
