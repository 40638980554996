/* Basic reset for Navbar */
.navbar-container {
  height: 3rem;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 100; /* make navbar stays above all content */
}

.navbar {
  border: none;
  border-radius: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  background-color: var(--color-main);
  box-shadow: 0 2px 8px var(--color-shadow);
  position: relative;
}

/* Logo styling */
.navbar-logo {
  max-width: 50px;
  margin-right: 0.5rem;
  border: 2px solid #313131;
  border-radius: 10px;
  box-shadow: 0 2px 4px var(--color-shadow);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.navbar-brand:hover .navbar-logo {
  transform: scale(1.05);
  box-shadow: 0 4px 8px var(--color-shadow);
}

.navbar-brand {
  color: var(--color-secondary-light) !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-decoration: none !important;
}

.navbar-brand:hover .navbar-brand-text span {
  color: var(--color-secondary-dark);
  transition: color 0.4s ease;
}

.navbar-brand-text {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-brand-text > span {
  text-align: center;
  margin: 0;
  transition:
    transform 0.3s ease,
    text-shadow 0.3s ease;
}

.navbar-brand-text > span:hover {
  text-shadow: 2px 2px 5px var(--color-shadow);
}

/* Link and Button styles */
.nav-link {
  color: var(--color-secondary-light) !important;
  transition: color 0.3s ease;
  font-weight: 500;
  text-decoration: none !important;
  padding: 0.5rem 1.5rem !important;
  font-size: 1.2rem;
}

.logout-button {
  background-color: var(--color-secondary-dark);
  border: none;
  border-radius: 25px;
  color: var(--color-white);
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    box-shadow 0.3s ease;
  padding: 0.5rem 2rem !important;
  font-size: 1.2rem;
  font-weight: 600;
  box-shadow: 0 4px 12px var(--color-shadow);
}

.logout-button:hover {
  background-color: #c0392b;
}

.logout-button:focus {
  outline: none;
}

/* Mobile styles */
@media (max-width: 907px) {
  .navbar-hamburger {
    display: none;
  }
}
@media (max-width: 520px) {
  .navbar-brand-text {
    align-items: center;
    font-size: 13px;
  }

  .nav-link,
  .logout-button {
    padding: 0.5rem 0.5rem !important;
    font-size: 1rem;
    display: block;
    width: 100%;
    text-align: center;
  }
}
