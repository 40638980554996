body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --color-main: #2c3e50;
  --color-white: #fff;
  --color-shadow: rgba(0, 0, 0, 0.2);
  --color-secondary-light: #ecf0f1;
  --color-secondary-dark: #c4831b;
}
