.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a {
  text-decoration: none;
}

.btn {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

.btn-round {
  width: 3rem;
  height: 3rem;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  cursor: pointer;
}

.btn-round:hover,
.btn:hover {
  transform: translateY(-2px);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Global Styles */
.content-wrap {
  flex: 1;
  padding-bottom: var(--footer-height, 1px);
}

html {
  height: 100%;
}

body {
  margin: 1px;
  padding: 1px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

/* Media Queries */
@media (max-width: 976px) {
  .footer-links p {
    font-size: 11px;
  }

  .footer-copyright {
    font-size: 10px;
  }
}

@media (max-width: 729px) {
  .footer-links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5rem;
  }

  .footer-links p {
    margin: 0.5rem;
  }
}

@media (max-width: 576px) {
  .footer-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
  }

  .footer-links p {
    margin: 0.5rem;
  }
}
