/* Sidebar Menu Styling */
.sidebar-menu {
  background-color: var(--color-main);
  color: var(--color-white);
  overflow: hidden;
  flex-direction: column;
  height: 100vh;
  width: 0;
  position: fixed;
  top: 0;
  padding: 0;
  z-index: 998;
  transition: all 0.3s ease-in;
}
.sidebar-menu.active {
  width: max-content;
  transition: all 0.3s ease-in;
}
.sidebar-header {
  gap: 40%;
  display: flex;
  padding: 10%;
  transition: all 0.3s ease;
}
/* Logo styling */
.sidebar-logo {
  max-width: 60px;
  margin-right: 0.5rem;
  border: 2px solid #313131;
  border-radius: 10px;
  box-shadow: 0 2px 4px var(--color-shadow);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}
.sidebar-body {
  display: flex;
  flex-direction: column;
  margin: 5% 5%;
  gap: 1.5rem;
}
.sidebar-link {
  display: flex;
  gap: 1rem;
  width: 100%;
  height: max-content;
  box-sizing: border-box;
  color: var(--color-white);
  cursor: pointer;
  font-size: 19px;
  border-radius: 10px;
  padding: 10px;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}
.sidebar-icon {
  color: var(--color-white);
}
.sidebar-link:hover {
  background-color: var(--color-shadow);
}
.sidebar-link:hover > .sidebar-icon,
.sidebar-link:hover > .sidebar-link {
  color: rgba(231, 231, 231, 0.601);
}

/* Mobile styles */
@media (max-width: 7000px) {
  .sidebar-menu.active {
    width: 18%;
  }
  .sidebar-header {
    gap: 60%;
  }
  .sidebar-link {
    font-size: 23px;
  }
}
@media (max-width: 2000px) {
  .sidebar-link {
    font-size: 19px;
  }
}
@media (max-width: 1100px) {
  .sidebar-menu.active {
    width: 18%;
  }
  .sidebar-header {
    gap: 30%;
  }
  .sidebar-link {
    font-size: 17px;
  }
}
@media (max-width: 907px) {
  .sidebar-menu {
    display: none;
  }
}
