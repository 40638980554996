.login-page {
  height: 70vh;
  margin-top: 6rem;
}
.login-container {
  max-width: 400px;
  margin: 5% auto;
  padding: 20px;
  border-radius: 5px;
  background-color: var(--color-secondary-light);
  box-shadow: 0 2px 10px rgba(160, 34, 34, 0.1);
  position: relative;
}

.header-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.login-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: left;
  font-weight: 600;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.logo-img {
  position: absolute;
  left: 40%;
  transform: translateX(-50%);
  top: 15px;
  height: 80px;
  border-radius: 50%;
  box-shadow:
    0px 3px 10px rgba(0, 0, 0, 0.2),
    0px 10px 20px rgba(0, 0, 0, 0.1);
  filter: grayscale(20%) contrast(110%) brightness(105%);
  animation: spin 30s infinite linear;
}

.login-form .input-group {
  margin-bottom: 5px;
}

.login-form label {
  display: block;
  margin-bottom: 7px;
  font-weight: 500;
}

.login-form input {
  width: 80%;
  padding: 10px 15px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  font-size: 16px;
  margin: 0 auto;
  display: block;
}

.password-wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.toggle-password {
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  transition:
    color 0.3s ease,
    box-shadow 0.3s ease;
  color: #9a9a9a;
}

.toggle-password:hover,
.toggle-password.password-shown {
  color: #0073e6;
}

.toggle-password:active {
  transform: translateY(-48%);
}

.login-form input:focus + .toggle-password {
  color: #0073e6;
}

.login-form input:focus {
  border-color: #0073e6;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 115, 230, 0.5);
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.login-btn {
  padding: 10px 20px;
  background-color: #0073e6;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.login-btn:hover {
  background-color: #005bb5;
}

.switch-container {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch-text {
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  color: #0073e6;
}

.switch-text:hover {
  opacity: 0.8;
}
