.Home {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  margin: 3rem;
  margin-top: 6rem;
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #2c3e50, #34495e);
  border-radius: 15px;
  box-shadow: 0 10px 20px var(--color-shadow);
  overflow: auto;
}

.link-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background: #ecf0f1;
  border-radius: 10px;
  box-shadow: 0 4px 10px var(--color-shadow);
  text-decoration: none;
  color: #333;
  border: 2px solid transparent; /* Added initial transparent border */
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease,
    color 0.3s ease,
    border-color 0.3s ease; /* Added border-color transition */
}

.link-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 15px var(--color-shadow);
  color: #0073e6;
  border-color: #0073e6; /* Added border color on hover */
}

.link-box h3 {
  margin-top: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.icon {
  color: var(--color-main);
  transition: color 0.3s ease;
}

@media (max-width: 768px) {
  .Home {
    grid-template-columns: repeat(2, 1fr);
    margin: 1rem;
    margin-top: 5rem;
    padding: 1rem;
  }

  .link-box h3 {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  .Home {
    grid-template-columns: 1fr;
    margin: 0.5rem;
    margin-top: 5rem;
    padding: 0.5rem;
  }

  .link-box h3 {
    font-size: 1rem;
  }
}
